<template lang="pug">
    #domain.mx-0.mx-sm-5.py-0.py-sm-5.center
        b-card.py-3(v-if="mounted")
            NavButtons(:disabled="nextButtonDisabled")
            
            h1.title OHFSS Self-Assessment Tool
            div.domain-domain(:style="{'width': $root.inDesktop ? '38%' : '100%'}" v-if="!$root.inDesktop")
                    h3.bold {{questionsObject.domain}}
            div.d-flex.align-items-center(v-if="$root.inDesktop")
                div.w-100
                    div.domain-domain(:style="{'width': $root.inDesktop ? '50%' : '100%'}")
                        h3.bold {{questionsObject.domain}}
                    div
                        p Please select the most relevant option for each statement with respect to your organisation.
                div.d-flex.flex-end.align-items-center
                    lottie-animation.m-0(:path="lottie" :width="300" :height="256")
                    p.font-weight-bold {{motivationalText}}
            div(v-for="(subdomain, subdomain_index) in questionsObject.subdomain" :key="'row_' + subdomain_index")
                div
                    h4.domain-subdomain-title {{subdomain.title}}
                    p(v-html="subdomain.description")
                div
                    b-row(v-if="$root.inDesktop") 
                        b-col(cols="2")
                        b-col(cols="7")
                            p.domain-subdomain-rating-title Rating Descriptors
                        b-col
                            p.domain-subdomain-rating-title Please select your responses below
                div
                    b-row(v-for="(row, row_index) in subdomain.rows" :key="'row_' + row_index"   
                    :style="[{'border': !row.selected ? '2px solid  #F18701 !important' : ''}, {'box-shadow': !row.selected ? '0 0 3px #F18701':''}]")
                        b-col(
                            :cols="$root.inDesktop ? 2 : 12" 
                            :class="['domain-subdomain-question', {'mt-4 mb-2': !$root.inDesktop}]"
                            :style="{'background-color': $root.inDesktop ? '#e9ecef' : ''}")
                            p {{row_index+1}}. {{row.question}}
                        b-col(:cols="$root.inDesktop ? 7 : 12")
                            b-table.max-height-width.legend-table(:items="row.ratingDescriptors" stacked="xl" head-variant= "light" bordered fixed)
                        b-col.d-flex.flex-column.justify-content-center(
                            :cols="$root.inDesktop ? 3 : 12"
                            :class="[{'mt-3': !$root.inDesktop}, 'border']"
                            :style="[{'margin': !$root.inDesktop ? '0px 15px' : ''}, {'flex': !$root.inDesktop ? 'unset' : ''}]")
                            b-form-radio( v-for="(option, option_index) in options" :key="'option_' + option_index" :value="option.value" :name="'option-question' + subdomain_index + row_index" v-model="row.selected" size="lg") {{option.text}}
            NavButtonsBottom.mb-3(:disabled="nextButtonDisabled")

            .d-flex.p-2 
                TechSupportLine
            
</template>

<script>
import NavButtons from '@/components/navbuttons.vue';
import NavButtonsBottom from '@/components/navbuttons.vue';
import TechSupportLine from '@/components/technicalSupportLine.vue';
import doctor from "@/assets/images/doctor.svg";

export default {
    name:"Domain",
    props: {
        data: Object,
    },
    data(){ 
        return {
            doctor,
            options:[
                { value: 1, text: 'Limited'},
                { value: 2, text: 'Fair' },
                { value: 3, text: 'Good' },
                { value: 4, text: 'Excellent' },
            ],
            questionsObject:{},
            mounted:false,
        }
    },
    mounted(){
        this.questionsObject = this.$store.state.allQuestionsObjects.find(o=>o.id==this.domain_id);
        this.mounted = true;
    },
    computed: {
        lottie(){
            return 'lottie/' + this.$store.state.lottie[this.$store.state.step-1];
        },
        motivationalText(){
            return this.$store.state.motivation[this.$store.state.step-1];
        },
        nextButtonDisabled(){
            return this.questionsObject.subdomain.some(
                subdomain =>subdomain.
                    rows.some(
                            row=>!row.selected
                        )
            )
        },
        domain_id(){
            return this.data.id;
        }
    },
    components: {
      NavButtons,
      NavButtonsBottom,
      TechSupportLine
    }
}
</script>