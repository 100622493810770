<template lang="pug">
  div.m-4
    h2 Enter your 
      span.blue-text work
      span  email address to receive your one-time password (OTP).
    b-form(@submit.prevent="confirm")
      //- Email input field
      b-form-input(v-if="$root.inMobile" class="input-mobile mt-4 mb-2" type="email" placeholder="Enter your email" v-model="email" :state="emailState" :required="true")
      b-form-input(v-else class="input mt-4 mb-2" type="email" placeholder="Enter your email" v-model="email" :state="emailState" :required="true")
      
      //- Invalid email message 
      b-form-invalid-feedback Email is invalid
      div(class="cover-button" tabindex="0")
        span(id="emailConfirm")
          b-button(variant="primary" size= 'lg' type="submit" :disabled="confirmButtonDisabledState" ) Confirm  
        b-tooltip(target="emailConfirm" v-if="tooltip" placement="right") Please ensure every field is filled
</template>

<script>
export default {
  name: 'Email',
  data(){
    return{
      email:'',
      confirmButtonDisabledState: true
    }
  },
  methods:{
    async confirm(){
        // Disabled button and await send OTP 
        this.setConfirmButtonDisabledState(true);
        const email = this.email.toLowerCase();

        this.$store.commit('setUserDetails', {userEmail: email});
        const result = await this.$store.dispatch('sendVerifyTOTP', {purpose: 'sendOTP', email: email, for: 'user'});

        // Go to next page on success
        if (result.success) {
            this.$router.push({path: '/register/otp'})
            return;
        }

        // Alert and enable button on failure 
        //alert(result.error);
        alert(`Please enter an email address with your SSA’s email domain. If you do not have one, please contact us for assistance.

fakhzan@thunderquote.com
emilng@thunderquote.com
Ng_hui_ling@ncss.gov.sg`)
        this.setConfirmButtonDisabledState(false);

    },
    checkEmail(){
        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return this.email.match(emailFormat) ? true: false;
    },
    setConfirmButtonDisabledState(payload){
      this.confirmButtonDisabledState = payload;
    }
  },
  computed:{
    tooltip(){
        return !this.emailState;
    },
    emailState(){
      if(this.email){
        const isEmailValid = this.checkEmail(this.email);   // Check email 
        this.setConfirmButtonDisabledState(!isEmailValid);  // Set confirm button disabled state 
        return isEmailValid;
      }
      else{
        return null;
      }
    }
  }
}
</script>