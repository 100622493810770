<template lang="pug">
  div.m-4
    //- Input field
    div
      h2 Enter the OTP sent to your email.
      p Please check your spam/junk mail folder. 
      b-form-input(v-if="$root.inMobile" class="input-mobile mt-4 mb-2" type="number" placeholder="Enter your OTP" v-model="totp")
      b-form-input(v-else class="input mt-4 mb-2" type="number" placeholder="Enter your OTP" v-model="totp")
    
    //- Confirm button 
    div(class="cover-button")
      b-button(variant="primary" size= 'lg' @click="confirm" :disabled="confirmButtonDisabled") Confirm          
</template>

<script>
export default {
  name: 'Otp',
  data(){
    return{
      totp: null,
      confirmButtonDisabled: false
    }
  },
  methods:{
    setConfirmButtonDisabled(payload){
      this.confirmButtonDisabled = payload;
    },
    async confirm(){
      // Disable button 
      this.setConfirmButtonDisabled(true); 
      let userPurpose = this.$route.path === '/register/otp' ? 'assignParticipants' : 'fillInForm';
      const result = await this.$store.dispatch('sendVerifyTOTP', {purpose: 'verifyOTP', token: this.totp, email: this.$store.state.userDetails.userEmail, userPurpose: userPurpose});

      // On success, mark authenticated & sync data 
      //if(result.result){
        this.$store.commit('markAuthenticated', result.token);
        const savedPath = await this.$store.dispatch('syncData', this.$store.state.userDetails.userEmail);

        if(this.$route.path === '/register/otp'){
           this.$router.push('/register/users');
           return; 
        }

        savedPath ? this.$router.push(savedPath) : this.$router.push('/details');
      //}
      //On failure, alert and enable button
      //else{
      //  alert("Invalid code!");
      //  this.setConfirmButtonDisabled(false); 
      //}

    }
  }
}
</script>