<template lang="pug">
    .d-flex.justify-content-between(:class="{'flex-column': $root.inMobile}")

        
        //Only show link if has previous / next meta attribute
        div(
            :class="[{'my-1': $root.inMobile}, {'mr-auto': !$root.inMobile}]"
            v-if="prevLink"
        )
            b-button(
                @click="scrollToTop"
                :block="$root.inMobile"
                class = 'ml-auto' 
                variant="primary" 
                size='lg' 
                :to="prevLink"
            ) {{prevText}}

        .d-flex(:class="[{'ml-auto': !$root.inMobile},{'flex-column': $root.inMobile}]")
            div(v-if="nextLink && $route.path != '/questions/overview'" size="lg")
                b-button(
                    :block="$root.inMobile"
                    :class="[{'my-1': $root.inMobile}, {'ml-auto': !$root.inMobile}]" 
                    variant="primary" 
                    size='lg' 
                    @click.prevent.stop="saveDraft"
                    style="margin-right: 5px"
                ) Save Draft

            div(v-if="nextLink" size="lg" id="nextButton")
                b-button(
                    :block="$root.inMobile"
                    v-if="nextText =='Next'"
                    @click="scrollToTop"
                    :class="[{'my-1': $root.inMobile}, {'ml-auto': !$root.inMobile}]" 
                    variant="primary" 
                    size='lg' 
                    :to="nextLink"
                    :disabled = "disabled"
                ) {{nextText}}
                b-button(
                    v-else
                    :block="$root.inMobile"
                    :class="[{'my-1': $root.inMobile}, {'ml-auto': !$root.inMobile}]" 
                    variant="primary" 
                    size='lg' 
                    @click="showConfirmModal"
                    :disabled = "disabled"
                ) {{nextText}}
        
        b-tooltip(target="nextButton" v-if="disabled" placement="right") Please ensure every field is filled

        //- Save draft alert
        b-modal(id="saved" variant="primary" title="Saved" v-model="save")
            p Draft has been saved successfully. Please use the same device and browser to return to your saved draft.
            template(#modal-footer="{cancel}")
                b-button(size="lg" @click="cancel") OK
        
        //- Confirm alert
        b-modal(id="confirm" title="Are you sure?")
            p Are you sure you are ready to submit the form and your feedback? 
            template(#modal-footer="{ok, cancel}")
                b-button(size="lg" :disabled = "confirmButtonDisabledState" variant="primary" @click="submit" ) Confirm
                b-button(size="lg" @click="cancel") Cancel
</template>

<script>
export default {
    name:'NavButtons',
    data(){
      return{
          confirmButtonDisabledState: false,
          save: false
      }
    },
    props:{
        disabled: Boolean
    },
    methods: {
        async scrollToTop(){
            // Scroll to top
            await this.$nextTick();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setTimeout(() => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }, 100)
        },
        async saveDraft(){
            // Save draft to local storage
            const payload={userEmail: this.$store.state.userDetails.userEmail, path: this.$route.fullPath};
            await this.$store.dispatch('storeInLocalStorage', payload);
            this.save = true;
        },
        showConfirmModal(){
            this.$bvModal.show('confirm');
        },
        async submit(){
            // Submit questionnaire answers and feedback
            this.confirmButtonDisabledState = true;
            this.$router.push('/');
            let {data} = await this.$store.dispatch('submit').catch(()=>{ return {data:false}})

            // Connection timeout
            if (!data) {
                this.$store.commit('setSubmitting', false);
                alert("Connection timeout! Please try again.")
                window.location.reload();
            }
        },
    },
    computed:{
        nextLink(){
            return this.$route.meta.next ? {name: this.$route.meta.next}: null
        },
        prevLink(){
            return this.$route.meta.prev ? {name: this.$route.meta.prev} : null
        },
        nextText(){
            return this.$route.meta.nexttext|| "Next"
        },
        prevText(){
            return this.$route.meta.prevtext|| "Previous"
        }
    }
}
</script>
