<template lang="pug">
    .d-flex.justify-content-center.align-items-center.main-div.w-100
        
        //- Alert users if they select themselves as last respondent  
        b-modal(id="confirm-last-respondent" title="Are you sure?" v-model="confirmLastModal")
            p Are you sure you are the last respondent? By checking this box and pressing Next, you will prevent your colleagues from completing this self-assessment form. 
            template(#modal-footer="{ok, cancel}")
                b-button(size="lg" variant="primary" @click="goToNextPage" :disabled="modalButtonDisabled") Understood, proceed
                b-button(size="lg" @click="cancel") Cancel
        
        //- Alert users if they select themselves as last respondent and the head of agency has not submitted a response
        b-modal(id="confirm-last-respondent-head" title="Are you sure?" v-model="confirmLastHeadModal" )
            p Are you sure you are the last respondent? The Head of Agency has yet to submit a response. By checking this box and pressing Next, you will prevent your colleagues from completing this self-assessment form. 
            template(#modal-footer="{ok, cancel}")
                b-button(size="lg" variant="primary" @click="goToNextPage" :disabled="modalButtonDisabled") Understood, proceed
                b-button(size="lg" @click="cancel") Cancel

        //- Confirm alert
        b-modal(id="confirm" title="Are you sure?" )
            p Are you sure your details are correct? 
            template(#modal-footer="{ok, cancel}")
                b-button(size="lg" variant="primary" @click="goToNextPage" :disabled="modalButtonDisabled") Yes, I am sure
                b-button(size="lg" @click="cancel") No
        
        
        //- Loading spinner when getting user's details via API 
        b-spinner(variant="primary" v-if="isLoading")

        //- Card
        b-card.px-0.px-sm-5.py-2.card-width(v-else)
            div.d-flex.flex-column.justify-content-center.align-items-center
                h1.text-center.font-weight-bold Tell Us More About Yourself
                
                div
                    h6.text-justify Before you attempt the self-assessment, please fill in your particulars. Please verify that all details (i.e. Name, Name of your Social Service Agency, Designation) is correct.
                    //- Q1 Name
                    div
                        b-form-group
                            p.font-weight-bold 1. Name 
                                span(class="required") {{requiredField}}
                            b-form-input(type="text" placeholder="Enter your name" v-model="name")
                    
                    //- Q2 Social Service Agency
                    div 
                        b-form-group
                            p.font-weight-bold 2. Social Service Agency (SSA) 
                                span(class="required") {{requiredField}}
                            b-form-input(disabled type="text" placeholder="Enter your SSA" v-model="SSA")
                            i.pt-1 If there is an error in the pre-filled text, kindly contact ThunderQuote via their 
                                span
                                    b-link(href="https://chat.whatsapp.com/JQkX1aLgVYO80FQZ4tAqol" target="_blank") WhatsApp Group
                    
                    //- Q3 Designation
                    div 
                        b-form-group
                            p.font-weight-bold 3. Designation 
                                span(class="required") {{requiredField}}
                            b-form-radio.mb-1(name='designation' value="Head of Agency" v-model ="designation") Head of Agency.
                                div
                                    i *Please note that the Head of Agency (and the Diagnostic Lead) MUST be one of the respondents of this self-assessment
                                //- b-form-input(v-if="designation == 'Head of Agency'" type="email" placeholder="Enter your email" v-model="q3HeadOfAgencyEmail" :state = "q3HeadOfAgencyEmailState")
                            b-form-radio.mb-1(name='designation' value="Board Member" v-model ="designation") Board Member
                            b-form-radio.mb-1(name='designation' value="Others" v-model ="designation") Others. Please elaborate: 
                                b-form-input(v-if="designation == 'Others'" type="text" placeholder="Enter your designation" v-model="otherDesignation")
                                
                    //- Last respondent checkbox 
                    div.mt-3
                        b-form-checkbox(v-model="lastRespondent" :value ="true" :unchecked-value ="false") I am the assigned Diagnostic Lead, and the final respondent from my SSA.

                    //- Next button 
                    div.d-flex  
                        div.ml-auto(id="detailsConfirm")
                            div
                                b-button(v-b-modal variant="primary" size= 'lg' @click="next" :disabled = "nextButtonDisabledState") Next
                        
                        //- Tooltip that is enabled when all the necessary fields are not filled 
                        b-tooltip(target="detailsConfirm" v-if="nextButtonDisabledState" placement="right") Please ensure every field is filled
</template>

<script>
export default {
  name: 'UserDetails',
  data(){
      return{
            requiredField: "* Required field(s)",
            headOfAgencyFieldDisabled: false,
            q3HeadOfAgencyEmailState: null,
            q4HeadOfAgencyEmailState: null, 
            q4Disabled: null,
            emails:[],
            userDetails: {},
            userEmail: this.$store.state.userDetails.userEmail,
            isLoading: true,
            confirmLastModal: false,
            confirmLastHeadModal: false,
            checkingOrg: false,
            modalButtonDisabled: false
      }
  },
  async mounted(){
      await this.getData(); // Get user data when mounted 
  },
  methods:{
    async getData(){
        // Get user data 
        await this.$store.dispatch('getData', this.userEmail);
        this.isLoading = false;
    },
    async next(){
        // If user is last respondent, check if the organisation's head of agency has submitted a response 
        if(this.lastRespondent){
            // Check if head of agency has completed if the user is not the head of agency
            if(this.designation != "Head of Agency"){
                this.checkingOrg = true;
                const {data} = await this.$store.dispatch('getList', {list: 'org'});
                const orgId = data[0].fields.id;
                const headOfAgencyCompleted = await this.$store.dispatch('getList', {list: 'user', where:{orgLookupId: orgId, designation: "Head of Agency", submissionStatus: "Pending"}});
                this.checkingOrg = false;
                
                // Alert user that head of agency has not submitted a response
                if(headOfAgencyCompleted.data.length){
                    this.$bvModal.show('confirm-last-respondent-head');
                    return;
                }
            }
            // Alert user about last respondent 
            this.$bvModal.show('confirm-last-respondent');
        }
        // Not last respondent, go to next page
        else{
             this.$bvModal.show('confirm');
            // this.goToNextPage();
        }
    },
    async goToNextPage(){
        this.modalButtonDisabled = true;

        // Update user details using payload and go to next page
        const payload = {
            updatedFields: this.finalData,
            userEmail: this.userEmail,
            list: 'user',
        }
        await this.$store.dispatch('updateList', payload);
        this.$router.push({path: '/questions'});
        this.modalButtonDisabled = false;
    },
    closeInvitationModal(){
        this.$bvModal.hide('invite');
    },
    checkDesignation(value){
        // No longer needed if Q4 is removed
        // If selected designation is Head of Agency, set Q4 as disabled and email input state as null
        if(value=="Head of Agency"){
            this.q4Disabled = true;
            this.q4HeadOfAgencyEmailState = null;
        }
        // Other designation choices selected, Q4 is not disabled
        else{
            this.q4Disabled = false;
        }
    },
    checkEmail(value, emailState) {
        // No longer needed if there are no email inputs 
        // Check if email is valid and set state 
        if(value){
            const isEmailValid = this.validateEmail(value);
            this[emailState] = isEmailValid;
            return isEmailValid;
        }
        // No value -> set state as null 
        this[emailState] = null;
        return null;
    },
    validateEmail(email){
        // No longer needed if there are no email inputs
        // Check if email is valid
        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(email){
            return email.match(emailFormat) ? true : false;      
        }
        // No email (no value to check) -> return null 
        return null;
    }
  },
  computed:{
    finalData() {
        // Process final data before storing 
        const ud = this.$store.state.userDetails;
        const finalData = {...ud};

        // Other designation is empty if "Others" not selected
        finalData.otherDesignation = finalData.designation == 'Head of Agency' || finalData.designation == 'Board Member' ? '' : finalData.otherDesignation;
        return finalData;
    },
    name:{
        get(){
            return this.$store.state.userDetails['Title'];
        },
        set(value){
            const data = { 
                    Title: value
            };
            this.$store.commit('setUserDetails', data);
        }
    },
    designation:{
        get(){
            this.checkDesignation(this.$store.state.userDetails.designation);
            return this.$store.state.userDetails.designation;
        },
        set(value){
            const data = { 
                designation: value
            }; 
            this.checkDesignation(this.$store.state.userDetails.designation);
            this.$store.commit('setUserDetails', data);
        }
    },
    otherDesignation:{
        get(){
            return this.$store.state.userDetails.otherDesignation;
        },
        set(value){
            const data = { 
                otherDesignation: value
            }; 
            this.$store.commit('setUserDetails', data);
        }
    },
    SSA:{
        get(){
            return this.$store.state.userDetails.SSA;
        },
        set(value){
            const data = { 
                SSA: value
            };
            this.$store.commit('setUserDetails', data);
        }
      },
    lastRespondent:{
        get(){
            return this.$store.state.userDetails.isLastRespondent;
        },
        set(value){
            const data = { 
                isLastRespondent: value,
            };
            this.$store.commit('setUserDetails', data);
        }
    },
    nextButtonDisabledState(){
        const userDetails = this.$store.state.userDetails;
        // When checking if org head of agency has submitted a response, disable button 
        if (this.checkingOrg) {
            return true;
        }
        // Check if question 1, 2 & 3 are answered
        if(!userDetails.Title || !userDetails.SSA || !userDetails.designation){
            return true;
        }
        // If Board Member and Others are selected 
        if(userDetails.designation == "Others"){
            // Check if "Other Designation" input is filled
            if(!userDetails.otherDesignation){
                return true; 
            }
        } 
        return false;
    }
  }
}
</script>