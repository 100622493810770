<template lang="pug">
  #app
    //- Fill questions button FOR TESTING ONLY
    //- b-button.no-print(@click="fillQuestions") Fill Questions
    //- b-button.no-print(@click="fillQuestions") Send Mail

    //- Submission pop up 
    b-modal(v-model="submitSplash" id ="submitModal" hide-header hide-footer no-close-on-backdrop no-close-on-esc)
      div
        lottie-animation(:path="lottieCanai")
      hr
      p.text-center.lead(v-if="submitDone && this.OHI")  Your Orgsanisation Health Index is {{OHI}}%. Thank you for your submission. You may now close this window. 
      p.text-center.lead(v-if="submitDone && !this.OHI") Thank you for your submission. You may now close this window. 
      p.text-center.lead(v-if="!submitDone") Sending data. Do not close this screen.
    
    //- Loading pop up
    b-modal(v-model="loadingSplash" hide-header hide-footer no-close-on-backdrop no-close-on-esc)
      div
        lottie-animation(path="lottie/submitting.json")
      hr
      p.text-center.lead Loading...

    //- Show different pages
    div(v-if="$route.path.indexOf('/questions') === 0")
      router-view
    div(v-else-if="$route.path == '/details'")
      #userDetails
        router-view
    div(v-else-if="$route.path == '/register/users'")
      #userDetails
        router-view
    div(v-else-if="$route.path == '/ohi'")
      router-view
    div(v-else)
      #cover
        b-container.cover-container(fluid)
          b-row.cover-main-row(align-v="stretch")
            b-col.cover-title-col(cols="12" lg="4")
              b-row.cover-title-row
                h1.cover-title(:class="[{'m-2': $root.inMobile}]") Organisational Health Framework for Social Services (OHFSS) Self-Assessment Form
                h5.cover-tagline(:class="[{'m-2': $root.inMobile}]") Your Organisational Health Matters
              b-row
                inline-svg.h-100(:src="doctor" v-if="!$root.inMobile")
            b-col.cover-content-col.d-flex.flex-column.justify-content-center(cols="12" lg="8")
                div.d-flex.flex-column
                  router-view

                div.ml-4.mt-1
                  TechSupportLine
                //- Logos
                //- Desktop view for logos 
                div.d-flex.align-items-end.my-5.justify-content-around.logo-container(v-if="$root.inDesktop")
                  div
                    a(href="https://www.ncss.gov.sg/" target="_blank")
                      b-img(fluid center :src="ncss" alt="An initiative of NCSS" class="ncss-img")
                  div
                    a(href="https://www.thunderquote.com/?__hstc=43267514.887500d4ddfb97ff8e73a87eec90c6c9.1635998885547.1638193790360.1638327265543.20&__hssc=43267514.1.1638327265543&__hsfp=3695372782" target="_blank")
                      b-img(fluid :src="tq_logo" alt="Powered by ThunderQuote" class="tq-img" )
                //- Tablet view for logos 
                div.d-flex.align-items-center.my-5.justify-content-around(v-if="$root.inTablet")
                  div
                    a(href="https://www.ncss.gov.sg/" target="_blank")
                      b-img(fluid center :src="ncss" alt="An initiative of NCSS" class="tablet-img")
                  div.my-4
                    a(href="https://www.thunderquote.com/?__hstc=43267514.887500d4ddfb97ff8e73a87eec90c6c9.1635998885547.1638193790360.1638327265543.20&__hssc=43267514.1.1638327265543&__hsfp=3695372782" target="_blank")
                      b-img(fluid :src="tq_logo" alt="Powered by ThunderQuote" class="tablet-img") 
                //- Mobile view for logos  
                div.d-flex.flex-row.align-items-center.my-5.justify-content-around(v-if="$root.inMobile")
                  div
                    a(href="https://www.ncss.gov.sg/" target="_blank")
                      b-img(fluid center :src="ncss" alt="An initiative of NCSS" class="mobile-img")
                  div.my-4
                    a(href="https://www.thunderquote.com/?__hstc=43267514.887500d4ddfb97ff8e73a87eec90c6c9.1635998885547.1638193790360.1638327265543.20&__hssc=43267514.1.1638327265543&__hsfp=3695372782" target="_blank")
                      b-img(fluid :src="tq_logo" alt="Powered by ThunderQuote" class="mobile-img")  
</template>

<script>
import tq_logo from "@/assets/images/tq-logo.png";
import ncss from "@/assets/images/ncss.jpg";
import doctor from "@/assets/images/doctor.svg";
import InlineSvg from 'vue-inline-svg';
import TechSupportLine from '@/components/technicalSupportLine';

export default {
  name: "App",
  data() {
    return {
      tq_logo,
      ncss,
      doctor,
      OHI: null
    };
  },
  async mounted() {
    window.location.href = "https://www.ncss.gov.sg/social-service-agencies/capability-building";
    let doneMessage = localStorage.getItem('submissionDoneMessage');
    let score = localStorage.getItem('score');

    if (doneMessage) {
      this.OHI = score;
      this.$store.commit('setSubmitted', true);
      localStorage.removeItem('submissionDoneMessage');
      localStorage.removeItem('score');
      this.$bvModal.show('submitModal');
    }

    this.$store.commit('setLoading', true);   

     // Check if user is logged in     
    let data = await this.$store.dispatch('init');  

    if (data.loggedIn) {
      console.log(data)
      await this.$store.commit('setUserDetails', {userEmail: data.user});  
      
      // Sync with data stored in local storage       
      const savedPath = await this.$store.dispatch('syncData', data.user);
      this.$store.commit('markAuthenticated');
  
      // Go to /register/users if the user is on /register pages & if user is the lead
      if (data.purpose != 'fillInForm') {
        if(this.$route.path.indexOf('/register') !==0){
          

          let r = await this.$bvModal.msgBoxConfirm("You are currently filling up the Diagnostic Lead form. Would you like to switch to the Self-Assessment Form instead?",
          {
            size:'lg',
            okTitle: 'Switch to Self-Assessment Form',
            cancelTitle: 'Continue to fill the Diagnostic Lead Form',
          })
          if(r){
            this.$store.dispatch('logOut', '/');
            return;
          }
          

        }
        this.$router.push('/register/users');
          
        

      }
      else{
        if(this.$route.path.indexOf('/register') ===0){
          let r = await this.$bvModal.msgBoxConfirm("You are currently filling up the Self-Assessment form. Would you like to switch to the Diagnostic Lead Form instead?",
          {
            size:'lg',
            okTitle: 'Switch to Diagnostic Lead Form',
            cancelTitle: 'Continue to fill the Self-Assessment Form',
          })
          if(r){
            this.$store.dispatch('logOut', '/register');
            return;
          }
        }
        // Go to saved path if there is one, else go to Tell Us More About Yourself page
        savedPath ? this.$router.push(savedPath): this.$router.push('/details');
      }
    }
    // Scroll to top
    this.globalScrollToTop();
    this.$store.commit('setLoading', false);
  },
  components: {
      InlineSvg,
      TechSupportLine
  },
  methods:{
    async fillQuestions() {
      //- For TESTING only
      this.$store.commit('fillQuestions');
      // await this.$store.dispatch('submitAnswers');
      // await this.$store.dispatch('sendDiagnosticMail');
    },
  },
  computed:{
    lottieCanai(){
      return this?.$store?.state?.submitted ? `lottie/submitted.json`:`lottie/submitting.json`; // Return different lottie depending on submission state 
    },
    submitSplash(){
      return this?.$store?.state?.submitting|| this?.$store?.state?.submitted || false;
    },
    loadingSplash(){
      return this?.$store?.state?.loading || false;
    },
    submitDone(){
      return this?.$store?.state?.submitted|| false
    }
  }
};
</script>
