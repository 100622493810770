import Vue from 'vue';
import VueRouter from 'vue-router';
import cover from '@/screens/cover.vue';
import email from '@/screens/email.vue';
import otp from '@/screens/otp.vue';
import details from '@/screens/details.vue';
import questions from '@/screens/questions.vue';
import overview from "@/screens/overview.vue";
import governance from '@/screens/governance.vue';
import leadership from '@/screens/strategyLeadership.vue';
import userCentricServices from '@/screens/userCentricServices.vue';
import processDigitalisation from '@/screens/processDigitalisation.vue';
import commPartnerships from '@/screens/commPartnerships.vue';
import finances from '@/screens/finances.vue';
import people from '@/screens/people.vue';
import feedback from '@/screens/feedback.vue';
import registerCover from '@/screens/registerCover.vue';
import registerEmail from '@/screens/registerEmail.vue';
import registerUsers from '@/screens/registerUsers.vue';
import register from '@/screens/register.vue';
import ohi from '@/screens/ohi.vue';
// import redirect from '@/screens/redirect.vue';

Vue.use(VueRouter);
const guardedPages = ['details', 'questions', 'feedback', 'users'];

const routes = [
  { 
    path: '/', 
    component: cover, 
    name: 'Cover' 
  },
  { 
    path: '/email', 
    component: email, 
    name: 'Email' 
  },
  { 
    path: '/otp', 
    component: otp, 
    name: 'Otp' 
  },
  { 
    path: '/details', 
    component: details, 
    name: 'Details'
  },
  {
    path: '/register',
    component: register,
    name: 'Register',
    redirect: '/register/cover',
    children: [{
      path: 'cover',
      component: registerCover,
      name: 'Cover',
    },
    {
      path: 'email',
      component: registerEmail,
      name: 'Email',
    },
    {
      path: 'users',
      component: registerUsers,
      name: 'Users',
    },
    {
      path: 'otp',
      component: otp,
      name: 'otp',
    }],
  },
  {
    path: '/questions', 
    component: questions, 
    name: 'Questions',
    redirect: '/questions/overview',
    children: [{
      path: 'overview',
      component: overview,
      name: 'Overview',
      meta: {
        prev: '',
        next: 'Strategy & Leadership'
      }
    },
    {
      path: 'leadership',
      component: leadership,
      name: 'Strategy & Leadership',
      meta: {
        prev: 'Overview',
        next: 'Governance'
      }
    },
    {
      path: 'governance',
      component: governance,
      name: 'Governance',
      meta: {
        prev: 'Strategy & Leadership',
        next: 'People'
      }
    },
    {
      path: 'people',
      component: people,
      name: 'People',
      meta: {
        prev: 'Governance',
        next: 'Finances'
      }
    },
    {
      path: 'finances',
      component: finances,
      name: 'Finances',
      meta: {
        prev: 'People',
        next: 'Digitalisation',
      }
    },
    {
      path: 'process-digitalisation',
      component: processDigitalisation,
      name: 'Digitalisation',
      meta: {
        prev: 'Finances',
        next: 'Communications & Partnerships'
      }
    },
    {
      path: 'communications-partnerships',
      component: commPartnerships,
      name: 'Communications & Partnerships', 
      meta: {
        prev: 'Digitalisation',
        next: 'User-Centric Services'
      }
    },
    {
      path: 'user-centric-services',
      component: userCentricServices,
      name: 'User-Centric Services',
      meta: {
        prev: 'Communications & Partnerships',
        next: 'Individual Scoring Summary Report'
      }
    },
    {
      path: 'ohi',
      component: ohi,
      name: 'Individual Scoring Summary Report',
      meta: {
        prev: 'User-Centric Services',
        next: 'Feedback'
      }
    },
    {
      path: 'feedback',
      component: feedback,
      name: 'Feedback',
      meta: {
        prev: 'Individual Scoring Summary Report',
        next: '/',
        nexttext: "Submit and Exit"
      }
    }
  ]}
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const store = router.app.$store;
  let guardedPage = false;

  // Check to to.path is one of the guarded pages
  for (let page of guardedPages) {
      if (to.path.includes(page)) {
          guardedPage = true;
          break;
      }
  }

  // Go to /register if user tries to access /register/users
  if (guardedPage && !store.state.isAuthenticated && to.path.includes('users')) {
    next('/register');
    return;
  }

  // Return to main page if user tries to access guarded pages and is not authenticated 
  if (guardedPage && !store.state.isAuthenticated) {
    next('/');
    return;
  }

  // Set step index when in question pages
  if (to.fullPath.indexOf('/questions') === 0) {
    const step = to.name;
    Vue.nextTick(()=> {
      const currentStepIndex = store.state.steps.findIndex(s => s === step);
      console.log(currentStepIndex);
      store.commit('setStepIndex', currentStepIndex);
    })   
  }
  
  next();
})

export default router;
